.swiss-army-select.flat {
  height: unset;

  &.disabled {
    color: $gray-900;
    background-color: $gray-300;

    // the "xxxxx-control" container
    > div {
      //the "xxxxx-ValueContainer
      > div {
        // xxxxx-singleValue container
        > div {
          color: inherit;
        }
      }
    }
  }

  // the "xxxxx-control" container
  > div {
    border-radius: unset;
    border-width: 0;
    border-color: unset;
    box-shadow: none;
    background-color: inherit;

    &:focus {
      border-color: unset;
    }

    &:hover {
      border-color: unset;
    }

    max-width: inherit;

    // the "xxxxx-ValueContainer"
    > div:nth-child(1) {
      div {
        // chips
        div:first-child {
          // chip text
          //max-width: 10em; // limit chip size so one fits in a single GridForm column control
        }
      }
    }

    > div:nth-child(2) {
      // indicators container
      padding-right: 8px;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
      > div:nth-child(2) {
        //border: 1px solid red;
      }
    }
  }

  > div:nth-child(1) {
    // dropdown menu
    margin-top: 0em;
    background-color: white;
    box-shadow: 4px 4px 6px 0px #a0a0a0;
  }

  div {
    // general text
    //color: unset;

    > svg {
      // indicators
      font-size: 1.35em;
      color: #878787;
    }
  }
}
