.btn {
  box-shadow: none !important;
}

.wide-button,
.wide-button:hover {
  padding: 4px 12px 4px 12px;
  text-align: left;
  width: 260px;
  font-family: 'Roboto';
}

.narrow-button,
.narrow-button:hover {
  padding: 4px 12px 4px 12px;
  text-align: left;
  width: 150px;
  font-family: 'Roboto';
}

.inspection .wide-button {
  width: 240px;
}

.button-icon {
  margin-right: 12px;
}

.button-caption {
  font-size: smaller;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}

.button-caption + .badge {
  margin-left: 1em;
}
