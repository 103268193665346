.card {
  border-radius: unset;
  box-shadow: unset !important;

  .btn {
    box-shadow: unset;
  }

  &.bg-light {
    background-color: $gray-600 !important;
  }

  &.input-panel {
    label {
      color: $turquoise;
      font-weight: bold;
      font-size: smaller;
      text-transform: uppercase;
    }

    input {
      padding: 0 1em;
      border-radius: unset;
    }

    .form-group.row {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
