@import 'colors';
@import 'variables';
@import '../../base';

@import '_colors';
@import '_variables';
@import 'components';
@import 'views';

.form-control {
  border-radius: 0 !important;
  font-weight: bold;
  font-size: 0.85rem;

  &:focus {
    border: 0;
    box-shadow: 0;
  }
}

.seamless.input-group {
  .form-control {
    border-right: 0;
  }

  .input-group-append {
    .input-group-text {
      background-color: white;
      border-radius: 0;
      font-size: 0.9rem;
    }
  }

  &.disabled {
    .input-group-text {
      background-color: $gray-300;

      svg {
        display: none;
      }
    }
  }
}

.row {
  &.light {
    background-color: $gray-600 !important;
  }
}

.pointer {
  cursor: pointer;
}
