.calendar {
  .calendar-navigation-bar {
    .link {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .calendar-date-bar {
    font-size: large;
    font-weight: bold;
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
  }

  .calendar-weekday-headers {
    border-top: 1px solid black;
    border-left: 1px solid black;

    div {
      flex: 1;
      font-weight: bold;
      border-right: 1px solid black;
    }
  }

  .calendar-day-grid {
    border-top: 1px solid black;
    border-left: 1px solid black;

    .calendar-day {
      flex: 1;
      border-right: 1px solid black;
      border-bottom: 1px solid black;
      min-height: 4em;

      .calendar-day-date {
        font-weight: bold;
      }
    }
  }
}
