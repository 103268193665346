$grid-header-box-height: 2.5rem;
$grid-header-box-border-width: 1px;
$grid-header-box-complete-vertical-estate: calc(
  $grid-header-box-height + ($grid-header-box-border-width * 2)
);

$grid-paginator-height: 2.5rem;
$grid-paginator-margin-top: 0.5rem;
$grid-paginator-complete-vertical-estate: calc(
  $grid-paginator-height + $grid-paginator-margin-top
);

.grid {
  position: relative;

  &.grid-font {
    font-weight: 400;
  }

  .outer-container {
    display: flex;
    flex-direction: column;

    .no-data-message {
      font-weight: bold;
      margin-top: 10px;
    }
  }

  .adjustable-grid-sub-container {
    flex: auto;
  }

  .header-container {
    display: flex;
    flex-direction: row;

    .header-box {
      position: relative;
      border-top: $grid-header-box-border-width solid #b2b2b2;
      border-bottom: $grid-header-box-border-width solid #888888;
      box-shadow: 0px 1px 0 0 #e6e6e6;
      background-color: #666666;
      color: white;
      width: 100%;
      height: $grid-header-box-height;
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
      text-transform: uppercase;
    }
  }

  .header-button {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .header-icon-primary {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  .header-icon-secondary {
    height: 16px;
    width: 16px;
    margin-right: 6px;
  }

  .grid-box {
    background-color: #f2f2f2;
    border: 1px solid #c5c5c5;
    border-top: none;
    box-shadow: 1px 1px 0 0 #d9d9d9;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .ps__rail-y {
    left: 0;
    right: unset;
    opacity: 0.6;
  }

  .composite-grid {
    display: flex;
    flex-direction: row;
  }

  .static-columns.wrapper {
  }

  .adjustable-columns.wrapper {
    background-color: #e6e6e6;
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }

  .ps__rail-x {
    top: 0;
    bottom: unset;
  }

  .adjustable-columns.left-only-gradient {
    background-image: linear-gradient(
      to right,
      rgb(192, 192, 192) 0px,
      rgba(192, 192, 192, 0) 12px
    );
  }

  .adjustable-columns.left-and-right-gradients {
    background-image: linear-gradient(
        to right,
        rgb(192, 192, 192) 0px,
        rgba(192, 192, 192, 0) 12px
      ),
      linear-gradient(
        to left,
        rgb(192, 192, 192) 0px,
        rgba(192, 192, 192, 0) 12px
      );
  }

  .action-column.wrapper {
  }

  .show-more-button {
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
  }

  .data-table.wrapper {
    padding-top: 0.5rem;
    display: table;
    width: 100%;
  }

  .data-table.header-row {
    display: table-row;
    height: 2.75rem;
    width: 100%;
  }

  .data-table.header-cell {
    display: table-cell;
    text-transform: uppercase;
    vertical-align: middle;
  }

  .data-table.header-cell:first-of-type {
    padding-left: 0.75rem;
  }

  .data-table.header-cell:last-of-type {
    padding-right: 0.75rem;
  }

  .data-table.header-cell-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .data-table.header-cell-text {
    font-weight: bold;
    color: #20798e;
  }

  .data-table.header-cell-sort {
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: #878787;
  }

  .data-table.data-row {
    display: table-row;
    width: 100%;
  }

  .data-table.data-row:last-of-type > .data-cell {
    border-bottom: none;
  }

  .data-table.data-cell {
    display: table-cell;
    font-weight: bold;
    //vertical-align: middle;
    border-bottom: 2px solid #d5d5d5;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .data-table.data-cell > .red-highlight {
    color: #cc0000;
  }

  .data-table.data-cell {
    .custom-checkbox-checkbox {
      height: 22px; // need to constrain height so checkboxes don't throw off row height across static/adjustable/action columns
    }
  }

  .data-table.data-cell:first-of-type {
    padding-left: 0.75rem;
  }

  .data-table.data-cell.clickable {
    color: #0995d3;
  }

  .show-more-row {
    border-top: 2px solid #d5d5d5;
    height: 35px;
    padding-left: 22px;
    display: flex;
    align-items: center;
  }

  /* Grid Selection Dropdowns */

  .grid-selection-dropdown-container {
    position: absolute;
    z-index: 1000;
    min-width: 30rem;
    left: 0px;
    top: 2.5rem;
    border: 1px solid #b0b0b0;
    border-top: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    color: black;
    text-transform: none;
    font-weight: bold;
    box-shadow: 4px 4px 6px 0px #a0a0a0;
  }

  .grid-selection-dropdown-footer {
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    border-top: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
  }

  .grid-selection-dropdown-footer-action {
    cursor: pointer;
  }

  .grid-selection-dropdown-footer-action-separator {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .grid-selection-dropdown-close-icon {
    padding-top: 2px;
    margin-left: 8px;
    color: #b3b3b3;
    font-size: large;
  }

  /* Filter Selection */

  .filter-selection-filter-list-container {
    display: flex;
    flex-direction: column;
    /*overflow-y: auto;*/ /* disable since it interferes with SwissArmySelect dropdowns */
  }

  .filter-selection-filter-list-item-container {
    border-bottom: 1px solid #b0b0b0;
  }

  .filter-selection-filter-list-item-container:last-of-type {
    border-bottom: none;
  }

  .filter-selection-filter-list-item {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
  }

  .filter-selection-filter-label {
    color: #0995d3;
    font-size: larger;
    margin-bottom: 4px;
  }

  .filter-selection-filter-content {
    .chip-select-filter-container {
      width: 25em;
    }

    .integer-filter-container {
      width: 13em;
    }
  }

  /* Column Selection */

  .column-selection-column-list-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
  }

  .column-selection-column-list-column {
    padding-right: 20px;
  }

  .column-selection-column-list-column:last-of-type {
    padding-right: 0px;
  }

  .column-selection-checkbox-row {
    display: flex;
    align-items: center;
  }

  /* Action Dropdown */

  .action-dropdown .dropdown-menu {
    margin-top: 2px !important; /* shift the menu up a bit */
    padding-top: 0px;
    padding-bottom: 0px;
    /* 2 px bigger than column width to compensate for border */
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    box-shadow: 4px 4px 6px 0px #a0a0a0;
    border-radius: 0;
  }

  .action-dropdown .dropdown-menu > a {
    font-weight: bold;
    padding: 6px 10px;
    text-align: right;
    white-space: normal;
  }

  .action-dropdown .dropdown-menu > a:hover {
    color: white;
    background-color: #b3b3b3;
    font-weight: 400;
  }

  .grid-section-header-row {
    h1 {
      font-size: 1.2rem;
      color: $turquoise;
      font-weight: bold;
      line-height: 2rem;
    }
    h3 {
      font-size: 0.95rem;
      color: $turquoise;
      font-weight: bold;
      line-height: 1rem;
    }
  }

  .paginator {
    height: $grid-paginator-height;
    margin-top: $grid-paginator-margin-top;
  }
}
