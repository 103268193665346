$jumbobadge-count-font-size: 1.5rem;
$jumbobadge-label-font-size: $jumbobadge-count-font-size / 1.5;

.jumbobadge {
  display: flex;
  height: calc(#{$jumbobadge-count-font-size} * 2);
  font-size: $jumbobadge-label-font-size;

  &.single-line-label {
    line-height: calc(#{$jumbobadge-count-font-size} * 2);
  }

  .count {
    color: $white;
    font-size: $jumbobadge-count-font-size;
    width: calc(#{$jumbobadge-count-font-size} * 2);
    text-align: center;
    margin-right: 1rem;
    line-height: calc(#{$jumbobadge-count-font-size} * 2);
  }

  .label {
    display: inline-block;
    font-size: $jumbobadge-label-font-size;
  }

  &.default {
    color: $gray-800;

    .count {
      color: $gray-800;
      background-color: $gray-600;
    }
  }

  &.danger {
    color: $danger;

    .count {
      background-color: $danger;
    }
  }

  &.info {
    color: $info;

    .count {
      background-color: $info;
    }
  }

  &.warning {
    color: $warning;

    .count {
      background-color: $warning;
    }
  }

  &.success {
    color: $success;

    .count {
      background-color: $success;
    }
  }

  &.primary {
    color: $primary;

    .count {
      background-color: $primary;
    }
  }
}
