@import 'insFormModal';

.project-ins-forms-list-container {
  $new: #666666;
  $inprogress: #ff6600;
  $ready: #009900;

  .project-ins-forms-list-header {
    //margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;

    .project-ins-forms-statistics {
      color: #0995d3;
      font-size: 1.45em;
    }

    .project-ins-forms-legend {
      display: flex;
      flex-direction: row;
      font-size: 0.5em;
      font-weight: bold;
      text-transform: uppercase;
      height: 2em;

      .legend-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 2em;

        .legend-item-box {
          min-width: 2em;
          width: 2em;
          max-width: 2em;
          height: 2em;
          margin-right: 0.5em;
        }

        .legend-item-box.new {
          background-color: $new;
        }

        .legend-item-box.in-progress {
          background-color: $inprogress;
        }

        .legend-item-box.ready {
          background-color: $ready;
        }

        .legend-item-box.error {
          height: 1.5em;
          min-width: 1.5em;
          width: 1.5em;
          max-width: 1.5em;
          border-radius: 50%;
          background-color: $danger;
        }

        .legend-item-box.comments {
          height: 1.5em;
          min-width: 1.5em;
          width: 1.5em;
          max-width: 1.5em;
          border-radius: 50%;
          background-color: $primary;
        }

        .legend-item-separator {
          border-left: 1px solid silver;
          height: 100%;
        }
      }
    }
  }

  .project-ins-forms-list {
    .project-ins-form-list-item {
      margin-bottom: 0.75em;

      .new {
        background-color: $new;
      }

      .in-progress {
        background-color: $inprogress;
      }

      .ready {
        background-color: $ready;
      }

      .card-body {
        padding: 0.75em;
      }

      .project-ins-form-list-item-content {
        display: flex;
        flex-direction: row;

        .spacer {
          width: 30px; // to match hidden round checkbox
        }

        .ins-form-name-container {
          padding: 0.5em;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .ins-form-name {
            color: white;
            width: 100%;
            cursor: pointer;
          }

          .ins-form-name.disabled {
            cursor: unset;
          }

          .ins-form-name-icons {
            margin-left: 0.75em;
            white-space: nowrap;
            color: white;
            height: 100%;
            display: flex;
            align-items: center;

            .ins-form-count-badge {
              margin-right: 1em;
              font-size: 0.75em;

              .badge {
                margin-right: 0.5em;
              }
            }

            .file-attachment-icon {
              height: 100%;
            }

            .view {
              font-size: larger;
            }

            .disabled {
              color: grey;
              cursor: unset;
            }
          }
        }

        .file-attachment-container {
          margin-left: 0.75em;

          .file-attachment-remove-container {
            width: 1.5em;
          }
        }

        .release-form-to-label {
          display: inline-flex;
          align-items: center;
          margin-left: 1.5em;
          font-size: x-small;
          font-weight: 900;
          line-height: 1;
          max-width: 40px;
          width: 40px;
          min-width: 40px;
        }

        .custom-checkbox-label {
          margin-left: 0.5em;
          margin-right: 0.5em;
          font-size: smaller;
        }

        .custom-checkbox {
          margin-left: 0.5em;
          margin-right: 0.5em;

          .custom-checkbox-checkbox.checked {
            color: $ready;
          }
        }

        .custom-checkbox.disabled {
          .custom-checkbox-checkbox {
            color: silver;
          }
        }
      }
    }
  }
}
