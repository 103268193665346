.dca {
  $theme: 'dca';
  $primary: #343434;
  $link-color: #0995d3;
  $site-header-background-color: grey;

  .text-primary.btn-link {
    color: $link-color !important;
  }
  // Note that if we ever upgrade to react-scripts 4.0.0, then these images need to be relocated from the /public/assets folder in to the /src/assets folder
  // because of how webpack handles them differently

  $header-logo-image: url('/assets/themes/dca/images/header-logo.png');
  $menu-logo-image: url('/assets/themes/dca/images/header-logo.png');
  $login-background-image: url('/assets/themes/dca/images/login-backgound.jpg');

  $page-header-text-color: #000;
  $page-header-background: linear-gradient(
    90deg,
    rgba(143, 146, 147, 1) 5%,
    rgba(208, 208, 207, 1) 15%,
    rgba(208, 208, 207, 1) 38%,
    rgba(143, 146, 147, 1) 55%,
    rgba(82, 83, 83, 1) 100%
  );

  @import 'themes/core/base';

  // When a modal is opened, then the .modal-open class is added to the main <body> tag.
  // Need to redefine this here because when it is wrapped inside our .sys class then
  // it doesn't match when imported from widgets/bootstrap/scss/modal. The included file
  // only matches on .modal-open and not "<other-class> .modal-open", so we need to
  // add it here in order to allow the overflow to be scrollable.
  &.modal-open {
    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

// We need to import all of this junk below in order to re-import the last "reboot" tyle
@import 'widgets/bootstrap/scss/functions';
@import 'widgets/bootstrap/scss/vendor/rfs';
@import 'widgets/bootstrap/scss/mixins';
@import 'core/variables';
@import 'core/mixins';

@import 'widgets/bootstrap/scss/variables';
@import 'widgets/bootstrap/scss/reboot';
