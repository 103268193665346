.login-banner {
  color: $white;
  background-color: $gray-800;
  display: flex;
  flex-direction: row;

  div {
    padding: 0.5em;
  }

  .login-banner-icon-wrapper {
    background-color: $gray-900;
  }
}
