.grid-form {
  display: flex;
  flex-direction: column;

  .grid-form-row {
    display: flex;
    flex-direction: row;

    .grid-form-column {
      display: flex;
      flex-direction: column;

      .grid-form-cell {
        font-size: smaller;
        background-color: #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 16px;
        padding-right: 12px;
        min-height: 36px;

        .grid-form-cell-label {
          width: 200px;
          min-width: 200px;
          max-width: 200px;
          text-transform: uppercase;
          color: $turquoise;
          font-weight: bold;
        }

        .grid-form-cell-input {
          width: 100%;
          height: 100%;

          //padding: 1em;

          .input-group,
          .form-control {
            padding: 0;
            height: 2.5rem;
          }
        }
      }
    }
  }

  .grid-form-chip-select > div > div > div:nth-child(2) {
    // indicators
    svg {
      margin-right: -0.4em; // shift the "X" indicator to right to line up with other such icons
    }
  }
}
