/**
  * Attention: Any variables you add here should end with !default to permit them to be overridden by specific themes
  */

$site-header-background-color: $blue-100 !default;

$page-header-background: unset !default;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;
$border-radius-xs: 0;

$btn-border-width: 0;
$btn-box-shadow: none;

$input-border-width: 0;
$input-btn-focus-box-shadow: none;

$grid-gutter-width: 1rem;
$popover-max-width: 800px !default;

$card-spacer-x: 1rem;
