.grid-layout {
  .grid-section-header-row {
    h1,
    .section-title {
      font-size: 1rem;
      color: $primary;
      font-weight: bold;
      line-height: 2rem;
      margin-bottom: 0;
    }
  }

  .field-set-column {
    > div.card {
      height: 100%;
    }

    &.compact {
      > div.card {
        height: unset;
      }
    }
  }

  .form-control {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .input-group-append,
  .input-group-prepend {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;

    span {
      padding-top: 0;
      padding-bottom: 0;
      border-right: none;
      border-radius: unset;
    }
  }

  .form-label-column {
    font-size: 0.8rem;
    padding-top: calc(0.8rem - 1px);
    padding-bottom: calc(0.8rem - 1px);
  }

  .card.bg-light {
    .btn.text-dark {
      color: $black !important;
    }
  }

  .select-dropdown-form-control {
    cursor: pointer;
  }

  .select-dropdown-menu {
    min-width: 100%;
    max-height: 20rem;
    overflow-y: auto;
  }

  .multi-check-select-dropdown-menu {
    min-width: 100%;
    padding: 0.5rem;

    .selection-label {
      margin-bottom: 0.5rem;
    }

    .clear-all-action {
      margin-top: 0.5rem;
      cursor: pointer;
    }
  }

  .multi-text-item-selection-list {
    .add-item-column {
      margin-right: 0.5rem;
      cursor: pointer;
      svg {
        font-size: 1rem;
      }
    }
  }

  .col {
    .selection-dropdown-container.as-label {
      background-color: unset;
      margin-left: -0.5rem;

      .selection-dropdown-button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
