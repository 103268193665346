.arg {
  $theme: 'arg';
  $primary: #0995d3;

  // Note that if we ever upgrade to react-scripts 4.0.0, then these images need to be relocated from the /public/assets folder in to the /src/assets folder
  // because of how webpack handles them differently
  $header-logo-image: url('/assets/themes/arg/images/header-logo.png');
  $menu-logo-image: url('/assets/themes/arg/images/menu-logo.png');
  $login-background-image: url('/assets/themes/arg/images/login-background.png');

  $page-header-text-color: #ffc926;
  $page-header-background: url('/assets/themes/arg/images/header_bg.png')
    no-repeat;

  @import 'themes/core/base';

  // When a modal is opened, then the .modal-open class is added to the main <body> tag.
  // Need to redefine this here because when it is wrapped inside our .sys class then
  // it doesn't match when imported from widgets/bootstrap/scss/modal. The included file
  // only matches on .modal-open and not "<other-class> .modal-open", so we need to
  // add it here in order to allow the overflow to be scrollable.
  &.modal-open {
    .modal {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

// We need to import all of this junk below in order to re-import the last "reboot" tyle
@import 'widgets/bootstrap/scss/functions';
@import 'widgets/bootstrap/scss/vendor/rfs';
@import 'widgets/bootstrap/scss/mixins';
@import 'core/variables';
@import 'core/mixins';

@import 'widgets/bootstrap/scss/variables';
@import 'widgets/bootstrap/scss/reboot';
