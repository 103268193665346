.page-breadcrumbs-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 20px;

  .page-breadcrumbs-breadcrumb {
    color: $primary;
    font-size: x-large;

    &:first-of-type {
      font-weight: bold;
      font-size: xx-large;
    }
  }

  .page-breadcrumbs-separator {
    padding-left: 12px;
    padding-right: 12px;
  }
}
