.site-header {
  background-color: $site-header-background-color;
  color: $white;
  line-height: 3em;

  .dropdown-menu {
    z-index: 2000;
  }

  .header-logo {
    height: 2.5rem;
    width: 200px;
    margin-left: 100px;
    background-image: $header-logo-image;
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }
}
