.project-heading-action-button-panel {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  button {
    margin-right: 0.5rem;
    min-width: 9rem;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0;
    }

    &.btn-link {
      color: $white;
    }
  }

  .null-button {
    width: 132px; // must match the standard button width
    min-width: 132px;
    max-width: 132px;
  }
}
