//  Core

.alert {
  border-radius: 0;
  border: none;

  &.alert-primary {
    background-color: $primary;
    color: lighten($primary, 80);
  }

  &.alert-warning {
    background-color: $success;
    color: lighten($success, 80);
  }

  &.alert-danger {
    background-color: $danger;
    color: lighten($danger, 80);
  }

  &.alert-warning {
    background-color: $warning;
    color: lighten($warning, 80);
  }
}
