.ins-form-modal {
  .ins-form-modal-dialog {
    width: 1060px;
    min-width: 1000px;
  }

  .heading-text {
    margin-top: 16px;
    color: #0995d3;
    font-size: 24px;
  }

  .form-comments-button {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .heading-action {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .heading-action-text {
    font-weight: bold;
    font-size: x-small;
    vertical-align: center;
  }

  .heading-action-icon {
    color: #b3b3b3;
    font-size: 20px;
    padding-top: 2px;
    margin-left: 8px;
  }
}
