.image-control {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: white;

  .image-display-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #999999;
    height: 100%;
    background-color: #999999;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    svg {
      font-size: 4em;
      align-self: center;
      cursor: pointer;
    }
  }

  .image-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    height: 4em;
    background-color: #666666;

    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        font-size: 1.5em;
        margin-right: 0.5em;
      }
    }

    .button:first-of-type {
      margin-right: 2em;
    }
  }
}
