.page {
  .page-header {
    background: $page-header-background;
    background-size: cover;
    color: $page-header-text-color;
    font-size: 0.85rem;
    padding: 1em 0;

    label {
      color: $white;
      font-weight: bold;
      text-transform: uppercase;
    }

    .actions {
      button {
        margin-right: 0.5rem;
        min-width: 9rem;
        text-transform: uppercase;
        &:last-child {
          margin-right: 0;
        }

        &.btn-link {
          color: $white;
        }
      }

      .search-input-group {
        .input-group-append {
          button {
            background-color: $white;
            color: $gray-900;
            min-width: unset;
          }
        }
      }
    }
  }

  .nav.nav-tabs {
    background-color: $gray-300;
    padding: 0 0.25rem;

    .nav-item {
      padding: 0.5rem 1rem;
    }
  }
}
