.MuiInputBase-root {
  &.Mui-disabled {
    color: $gray-900 !important;
    background-color: $gray-300 !important;
  }
}

.MuiFormControl-root.MuiTextField-root {
  height: 36px;
  width: 100%;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  border-radius: 0;
  height: 36px;
  padding: 0;
  background-color: white;
}

input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  height: 36px !important;
  padding: 0px 0px 0px 12px;
  width: 100%;
}

fieldset.PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline {
  height: 41px !important;
  padding: 0px !important;
  border: none;
}

input.MuiOutlinedInput-input {
  font-size: small;
  font-weight: bold;
}

.MuiInputAdornment-root.MuiInputAdornment-positionEnd,
button.MuiButtonBase-root.MuiIconButton-root {
  color: #878787;
}

button.MuiButtonBase-root.MuiIconButton-root {
  border: none !important;
  padding-right: 6px;
}

button.MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: transparent;
}

p.MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
  margin-left: 0;
  margin-top: -2px;
}

button.MuiButtonBase-root.MuiPickersDay-day.MuiPickersDay-dayDisabled {
  color: lighten(#878787, 35);
}
